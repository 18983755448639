<template>
  <div class="hold-transition">
    <div class="row">
      <div class="col-md-12">
        <table
          class="
            table table-bordered table-striped table-hover table-sm
            text-sm
          "
        >
          <thead class="bg-gray text-center">
            <tr>
              <th class="text-center">Tipo Sitio</th>
              <th class="text-center">Sitio</th>
              <th class="text-center">Direccion</th>
              <th class="text-center">Contacto</th>
              <th class="text-center">Número de Contacto</th>
              <th class="text-center">Correo</th>
              <th class="text-center">Acción</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr v-for="ruta in dato.ruta" :key="ruta.id">
              <td class="text-center">
                <span
                  class="badge"
                  :class="ruta.tipo_sitio == 1 ? 'bg-success' : 'bg-primary'"
                >
                  {{ ruta.tipo_sitio == 1 ? "Origen" : "Destino" }}
                </span>
              </td>
              <td class="text-center">
                {{ ruta.nombre }}
              </td>
              <td class="text-center">
                {{ ruta.direccion }}
              </td>
              <td class="text-center">
                {{ ruta.nombre_contacto }}
              </td>
              <td class="text-center">
                {{ ruta.telefono_contacto }}
              </td>
              <td class="text-center">
                {{ ruta.email_contacto }}
              </td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  data-toggle="modal"
                  data-target="#modal-form-tipo-servicio"
                  style="cursor: pointer"
                  @click="$parent.abrirModal('Editar', dato.id)"
                >
                  <i class="fas fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "CsSolicitudesNacionalesRutas",
  components: {},
  props: {
    indice: Number, // Definir la prop "indice"
    dato: Object, // Definir la prop "dato" u otro tipo de dato según corresponda
  },

  data() {
    return {};
  },
  computed: {},
  validations: {},
  methods: {},
};
</script>
